<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true">
    <div id="electricity">
      <div class="consumption">
        <div class="subtitle">
          <!-- <img src="../../assets/images/electricity/title.png" alt="" />
          <p>发电情况</p> -->
        </div>
        <!--        <ul class="consumption-chart-icon">-->
        <!--          <li class="power-top">尖</li>-->
        <!--          <li class="power-peak">峰</li>-->
        <!--          <li class="power-flat">平</li>-->
        <!--          <li class="power-valley">谷</li>-->
        <!--        </ul>-->
        <el-date-picker style="width: 105px" class="z-index" v-model="periodDay" type="date" placeholder="日"
                        value-format="yyyy-MM-dd" format="dd" :clearable="false" popper-class="echarts-picker"
                        @change="dayChange">
        </el-date-picker>
        <div id="consumptionChart"></div>
      </div>
      <header class="header">
        <div class="flex-horizontal-between">
          <ul class="nav">
            <li class="home-page" @click="jumpHome">
              <img src="../../assets/images/electricity/home-page.png" alt=""/>
              <span>首页</span>
            </li>
            <li class="date">
              <img src="../../assets/images/electricity/date.png" alt=""/>
              <span>{{ date }} {{ week }} {{ time }} </span>
            </li>
          </ul>
          <div class="title">
            <img src="../../assets/images/electricity/logo.png" alt=""/>
            <span>智慧能源聚合管理平台 驾驶舱-源</span>
          </div>
          <ul class="operate">
            <li class="address">
              <img src="../../assets/images/cockpit/address.png" alt=""/>
              <span>地区：北京 天气：{{ tq.tqxx }} 风向：{{ tq.fxms }} 空气质量：{{
                  tq.kqzl
                }}</span>
            </li>
            <li class="user">
              <img src="../../assets/images/cockpit/avatar.png" alt=""/>
              <span>{{ userInfo.userName }}</span>
            </li>
            <div class="line"></div>
            <li class="exit" @click="loginOut">
              <img src="../../assets/images/cockpit/exit.png" alt=""/>
              <span>退出</span>
            </li>
          </ul>
        </div>
      </header>
      <div class="main">
        <div class="content-left">
          <div class="fault">
            <div class="subtitle">
              <!-- <img src="../../assets/images/electricity/title.png" alt="" /> -->
              <!-- <p>综合数据</p> -->
            </div>
            <div id="failureChart"></div>
            <ul class="failure-legend">
              <div class="failure-total">
                <p>发电站总数量</p>
                <img src="../../assets/images/electricity/fault-arrow.png" alt=""/>
                <!-- <span>{{ powerCut.planPowerOutages }}</span> -->
                <span>{{ stationCount || 0 }}座</span>

              </div>
              <div class="failure-total">
                <p>井网总容量</p>
                <img src="../../assets/images/electricity/fault-arrow.png" style="margin-left:20px" alt=""/>
                <!-- <span>{{ powerCut.planPowerOutages }}</span> -->
                <span>{{ totalCapacity || 0 }}GW</span>

              </div>
              <li style="margin-top:20px">
                <div class="failure-square" style="background: #40ecc2"></div>
                <p style="margin-right: 20px">已投运</p>
                <p>{{ operatedCapacity || 0 }}座</p>
                <p style="position: absolute;right: 10px;">{{ operatedCapacityNum || 0 }}GW</p>
              </li>
              <li>
                <div class="failure-square"></div>
                <p style="margin-right: 32px">计划</p>
                <p>{{ plannedCapacity || 0 }}座</p>
                <p style="position: absolute;right: 10px;">{{ plannedCapacityNum || 0 }}GW</p>

              </li>
              <li class="">
                <div class="failure-square" style="background: #f6cd29"></div>
                <p style="margin-right: 32px">在建</p>
                <p>{{ underConstructionCapacity || 0 }}座</p>
                <p style="position: absolute;right: 10px;">{{ underConstructionCapacityNum || 0 }}GW</p>
              </li>
            </ul>
          </div>
          <div class="trend">
            <div class="subtitle">
              <!-- <img src="../../assets/images/electricity/title.png" alt="" /> -->
              <!-- <p>综合数据</p> -->
            </div>
            <div id="shgx">
              <p style="top:14px;right:20px">二氧化碳减排</p>
              <p style="top:38px;right:20px">{{ eqCarbonEmis || 0 }}吨</p>
              <p style="top:92px;left:20px">等效植树</p>
              <p style="top:114px;left:20px">{{ eqTreeNumber || 0 }}棵</p>
              <p style="bottom:46px;right:20px">节约标准煤</p>
              <p style="bottom:24px;right:20px">{{ eqStdCoal || 0 }}吨</p>
            </div>
          </div>
          <div class="demand">
            <div class="subtitle">
              <!-- <img src="../../assets/images/electricity/title.png" alt="" /> -->
              <!-- <p>健康指数</p> -->
            </div>
            <div id="demandChart"></div>
            <div class="titles"><p>87.5%</p><span>消缺率</span></div>
            <ul class="failure-legend">
              <li class="li1" style="margin-bottom:30px">
                <p style="margin:10px 60px 10px 40px;" class="p1 p2">缺陷数</p>
                <p style="margin-right: 10px">{{ "24" }}</p>
              </li>
              <li class="li2">
                <p style="margin:10px 60px 10px 40px;" class="p1 p3">消缺数</p>
                <p style="margin-right: 10px">{{ "21" }}</p>
              </li>
            </ul>
            <div class="bottom">
              <div class="list_one list_two">
                <p>{{ '良好' }}</p>
                <span>设备健康状态</span>
              </div>
              <div class="list_one list_three">
                <p>{{ '86.61%' }}</p>
                <span>设备健康率</span>
              </div>
            </div>
          </div>
        </div>
        <div class="middle">
          <!-- 搜索场站图表 -->
          <div class="bus-stop" @click="openDialog">场站</div>
          <!-- 场站总用电量GWh -->
          <div class="total-electricity">
            <div class="text">
              <img src="../../assets/images/electricity/total-electricity.png" alt=""/>
              <span>总发电量GWh</span>
            </div>
            <ul class="total">
              <li v-for="(item, index) in totalPower" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="map">
            <div id="mapChart"></div>
            <div v-if="stationDialog" class="station-info">
              <!-- <div class="arrow"></div> -->
              <div class="pop-ups">
                <div class="dialog-title">
                  <img src="../../assets/images/cockpit/mark.png" alt=""/>
                  <p>{{ stationInfo.name }}</p>
                </div>
                <ul class="dialog-content">
<!--                  <li class="year-electricity">-->
<!--                    <div class="year-GWh">-->
<!--                      <span>{{ stationInfo.yearElectricity || 0 }}</span>-->
<!--                      <p>年总用电量 (GWh)</p>-->
<!--                    </div>-->
<!--                  </li>-->
                  <li class="dialog-form">
                    <div>客户名称:</div>
                    <p>&nbsp{{ stationInfo.copatyInfo.companyName }}</p>
                  </li>
                  <li class="dialog-form">
                    <div>场站形式:</div>
                    <p v-if="stationInfo.type == '1'">&nbsp{{'火力发电站'}}</p>
                    <p v-if="stationInfo.type == '2'">&nbsp{{'水力发电站'}}</p>
                    <p v-if="stationInfo.type == '3'">&nbsp{{'核电站'}}</p>
                    <p v-if="stationInfo.type == '4'">&nbsp{{'风力发电站'}}</p>
                    <p v-if="stationInfo.type == '5'">&nbsp{{'分布式光伏发电站'}}</p>
                  </li>
                  <li class="dialog-form">
                    <div>装机容量:</div>
                    <p>&nbsp{{ stationInfo.capacity }}&nbsp kva</p>
                  </li>
                  <li class="dialog-form">
                    <div>场站地址:</div>
                    <el-tooltip class="item" effect="dark" :content="stationInfo.address" placement="top">
                      <p>&nbsp{{ stationInfo.address.length > 8 ? stationInfo.address.substring(0,8) + '...' : stationInfo.address.substring(0,8) }}</p>
                    </el-tooltip>
                  </li>
<!--                  <li class="dialog-form">-->
<!--                    <div>昨日用电量</div>-->
<!--                    <p>{{ stationInfo.yesterdayElectricity || 0 }}kWh</p>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <button :class="isStringInArray('lookItemElectricityHome') ? '' : 'btnShowAuthority'-->
<!--                      " @click="goDetails()">-->
<!--                      查看详细 >>-->
<!--                    </button>-->
<!--                  </li>-->
                </ul>
                <div class="dialog-button dialog-content">
                  <button :class="isStringInArray('lookItemElectricityHome') ? '' : 'btnShowAuthority'
                      " @click="goDetails()">
                    查看详细 >>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ul class="weather">
            <li v-if="tq.tqxx == '大风'">
              <img src="../../assets/images/cockpit/wind.png" alt=""/>
            </li>
            <li v-if="tq.tqxx == '暴雨'">
              <img src="../../assets/images/cockpit/rain.png" alt=""/>
            </li>
          </ul>
        </div>
        <div class="content-right">
          <div class="flex-horizontal-between">
            <div class="subtitle">
              <img src="../../assets/images/electricity/title.png" alt=""/>
              <p>报告通知</p>
            </div>
          </div>
          <ul class="accident">
            <div class="flex-horizontal-between link">
              <li @click="goAlarmList('4')">
                <img src="../../assets/images/electricity/special.png" alt=""/>
                <div>
                  <span style="color: #c92749">{{
                      specialAlarm.alarmCount || 0
                    }}</span>
                  <p>告警情况调整</p>
                </div>
              </li>
              <li @click="goAlarmList('3')">
                <img src="../../assets/images/electricity/major.png" alt=""/>
                <div>
                  <span style="color: #ff6a24">{{
                      majorAlarm.alarmCount || 0
                    }}</span>
                  <p>任务数量统计</p>
                </div>
              </li>
            </div>
            <div class="flex-horizontal-between link">
              <li @click="goAlarmList('2')">
                <img src="../../assets/images/electricity/serious.png" alt=""/>
                <div>
                  <span style="color: #f6cd29">{{
                      seriousAlarm.alarmCount || 0
                    }}</span>
                  <p>计划数量统计</p>
                </div>
              </li>
              <li @click="goAlarmList('1')">
                <img src="../../assets/images/electricity/ordinary.png" alt=""/>
                <div>
                  <span style="color: #51a1ff">{{
                      averageAlarm.alarmCount || 0
                    }}</span>
                  <p>任务完成率</p>
                </div>
              </li>
            </div>
          </ul>
          <div class="load">
            <div class="subtitle">
              <!-- <img src="../../assets/images/electricity/title.png" alt="" />
              <p>24小时实时负载统计</p> -->
            </div>
            <div id="loadChart"></div>
          </div>
          <div class="device">
            <div class="subtitle">
              <!-- <img src="../../assets/images/electricity/title.png" alt="" />
              <p>各中心新旧设备占比</p> -->
            </div>
            <el-date-picker style="width: 105px" class="z-index" v-model="periodDay2" type="date" placeholder="日"
                            value-format="yyyy-MM-dd" format="dd" :clearable="false" popper-class="echarts-picker"
                            @change="dayChange2"></el-date-picker>
            <div id="deviceChart1"></div>
            <!-- <div id="demandChart"></div> -->
            <div class="titles"><p>{{ totalEnergy }}</p><span>发电总量(GWh)</span></div>
            <ul class="rush-repair fault-legend">
              <li style="margin-top: 20px;">
                <div style="background: #3666c6"></div>
                <p style="margin-right: 70px">自用</p>
                <p style="color: #c5d8ff">{{ selfUseB }}%</p>
              </li>
              <li>
                <div style="background: #40ecc2"></div>
                <p style="margin-right: 70px">售电</p>
                <p style="color: #c5d8ff">{{ electricitySaleB }}%</p>
              </li>
              <li>
                <div style="background: #f6cd29"></div>
                <p style="margin-right: 70px">弃光</p>
                <p style="color: #c5d8ff">{{ abandonedLightB }}%</p>
              </li>
            </ul>
            <div class="bottom">
              <div class="list_one list_two">
                <p>{{ selfUse }}</p>
                <span>自用(GWh)</span>
              </div>
              <div class="list_one list_three">
                <p>{{ electricitySale }}</p>
                <span>售电(GWh)</span>
              </div>
              <div class="list_one list_four">
                <p>{{ abandonedLight }}</p>
                <span>弃光 (GWh)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="screenDialog" class="dialog">
        <div class="close">
          <img src="../../assets/images/dialog/close.png" alt="" @click="screenDialog = false"/>
        </div>
        <div class="screen-title">请选择要查询的场站</div>
        <div class="screen-subtitle" style="margin-top: 17px">
          <img src="../../assets/images/dialog/branch.png" alt=""/>
          <span>客户</span>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li v-for="(item, index) in branchData" :style="{ color: item.id === branchId ? '#ffc74a' : '' }"
                :key="index" @click="branchClick(item)">
              {{ item.companyName }}
            </li>
          </ul>
        </div>

        <div class="screen-subtitle">
          <img src="../../assets/images/dialog/property.png" alt=""/>
          <span>运维单位</span>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li class="property" v-for="(item, index) in propertyData" :key="index" @click="propertyClick(item)"
                :style="{ color: item.id === propertyId ? '#ffc74a' : '' }">
              {{ item.companyName }}
            </li>
          </ul>
        </div>

        <div class="station-title">
          <div class="screen-subtitle">
            <img src="../../assets/images/dialog/station.png" alt=""/>
            <span>场站</span>
          </div>
          <div class="search">
            <el-input placeholder="请根据场站名称进行搜索" v-model="stationName"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getStationThree">搜索</el-button>
          </div>
        </div>
        <div style="height: 150px">
          <ul class="screen-content">
            <li class="all-station" v-for="(item, index) in stationData" :key="index" @click="stationClick(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>

        <!-- <div class="dialog-footer">
          <button>取消</button>
          <button>确定</button>
        </div> -->
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import * as echarts from "echarts";
import jingjinji from "../../assets/json/jingjinji.json";
import {monitoringLeftApi} from "@/api/monitoringLeft.js";
import {loginApi} from "@/api/login.js";
import {cockpitApi} from "@/api/cockpit.js";
import moment from "moment";
import "moment/locale/zh-cn"; // 引入中文语言包
moment.locale("zh-cn"); // 设置语言为中文
export default {
  components: {},
  data() {
    return {
      authorityListBtn: [],
      screenDialog: false,
      stationId: "",
      branchId: "",
      propertyId: "",
      stationName: "",
      branchData: [],
      propertyData: [],
      stationData: [],
      userInfo: {},
      date: "",
      time: "",
      week: "",
      year: "",
      timer: null,
      stationDialog: false,
      stationInfo: {},
      coordinate: [],
      mapChart: null,

      stationCount: '',
      totalCapacity: '',
      totalConsumption: '',
      eqStdCoal: '',
      eqCarbonEmis: '',
      eqTreeNumber: '',
      operatedCapacity: '',
      operatedCapacityNum: '',
      plannedCapacity: '',
      plannedCapacityNum: '',
      underConstructionCapacity: '',
      underConstructionCapacityNum: '',
      selfUse: '',
      electricitySale: '',
      abandonedLight: '',
      selfUseB: '',
      electricitySaleB: '',
      abandonedLightB: '',
      totalEnergy: '',


      realData: {}, //实时数据
      averageAlarm: {
        alarmCount: "",
      }, //告警情况统计
      seriousAlarm: {
        alarmCount: "",
      }, //告警情况统计
      majorAlarm: {
        alarmCount: "",
      }, //告警情况统计
      specialAlarm: {
        alarmCount: "",
      }, //告警情况统计
      powerCut: {},
      totalPower: "",
      rushRepair: {},
      keepRepair: {},
      periodDay: null,
      periodDay2: null,
      tq: {},
      day: "",
    };
  },
  created() {
    //默认今天
    // this.periodDay = moment().format('YYYY-MM-DD')
    //默认昨天
    this.periodDay = moment().subtract(1, 'days').format('YYYY-MM-DD')
    // this.periodDay2 = moment().format('YYYY-MM-DD')
    this.periodDay2 = moment().subtract(1, 'days').format('YYYY-MM-DD')
    // const date = new Date(); // 获取当前时间
    // let year = date.getFullYear(); // 获取当前年份
    // let month = date.getMonth() + 1; // 获取当前月份
    // this.day = date.getDate();
    // if (month < 10) {
    //   this.periodMonth = `${year}-0${month}`;
    // } else {
    //   this.periodMonth = `${year}-${month}`;
    // }
  },
  mounted() {
    // 获取登录用户信息
    this.userInfo = JSON.parse(sessionStorage.getItem("user_info"));
    this.authorityListBtn = sessionStorage.getItem("roleInfo");
    // 获取当前时间
    this.time = moment().format("LTS");
    this.week = moment().format("dddd");
    this.date = moment().format("LL");
    this.year = moment().format("yyyy");
    this.currentTime();
    this.init();
    this.setWeather();
  },
  methods: {
    //天气接口
    setWeather() {
      // 创建一个 Date 对象
      var now = new Date();
      // 获取当前年份
      var currentYear = now.getFullYear();
      // 获取当前月份（注意，月份是从0开始计数的，所以需要加1）
      var currentMonth = now.getMonth() + 1;
      // 获取当前日期
      var currentDate = now.getDate();
      //获取当前小时
      var currentHour = now.getHours();
      if (currentMonth < 10) {
        currentMonth = '0' + currentMonth
      }
      if (currentDate < 10) {
        currentDate = '0' + currentDate
      }
      let param = {
        qybms: "110000",
        fPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ":00",
        tPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ":00",
      };
      monitoringLeftApi.getWeatherHourData(param).then((res) => {
        if (res.code == 200) {
          this.tq = res.data[0].sdtqInfos[0];
          // console.log("111111",this.tq);
        }
      });
    },
    //获取场站
    getStationList() {
      cockpitApi.powergenStationList().then((res) => {
        this.branchData = res.data.companyInfoList;
        this.propertyData = res.data.propertyUnits;
        this.stationData = res.data.stationInfos;
        this.drawMap();
      });
    },
    //获取第三级场站
    getStationThree() {
      let params = {
        ownerCompanyId: this.branchId,
        operationCompanyId: this.propertyId,
        parameter: this.stationName
      };
      cockpitApi.powergenStationListById(JSON.stringify(params)).then((res) => {
        this.stationData = res.data;
        this.drawMap();
      });
    },
    //综合数据
    getPowergenComprehensiveData() {
      cockpitApi.powergenComprehensiveData().then(res => {
        if (res.code == '200') {
          this.stationCount = res.data.stationCount
          this.totalCapacity = Number(res.data.totalCapacity / 1000000000).toFixed(2)
          this.operatedCapacity = res.data.operatedCapacity
          this.operatedCapacityNum = Number(res.data.operatedCapacityNum / 1000000000).toFixed(2)
          this.plannedCapacity = res.data.plannedCapacity
          this.plannedCapacityNum = Number(res.data.plannedCapacityNum / 1000000000).toFixed(2)
          this.underConstructionCapacity = res.data.underConstructionCapacity
          this.underConstructionCapacityNum = Number(res.data.underConstructionCapacityNum / 1000000000).toFixed(2)
          this.totalConsumption = Number(res.data.totalConsumption / 1000000000).toFixed(2)
          this.eqStdCoal = Number(res.data.eqStdCoal).toFixed(2)
          this.eqCarbonEmis = Number(res.data.eqCarbonEmis).toFixed(2)
          this.eqTreeNumber = Number(res.data.eqTreeNumber).toFixed(2)
          this.drawFailure(res.data.operatedCapacity, res.data.plannedCapacity, res.data.underConstructionCapacity)
        }
      })
    },
    //发电情况
    getPowergenPowerGenerationStatus() {
      const param = {
        day: this.periodDay
      }
      cockpitApi.powergenPowerGenerationStatus(param).then(res => {
        if (res.code == '200') {
          this.drawConsumption(res.data)
        }
      })
    },
    //7日发电预测量
    getPowergenSevenDayPowerGenerationForecast() {
      cockpitApi.powergenSevenDayPowerGenerationForecast().then(res => {
        if (res.code == '200') {
          this.drawLoad(res.data.sevenPower)
        }
      })
    },
    //光伏发电使用情况
    getPowergenPhotovoltaicPowerUsage() {
      const param = {
        day: this.periodDay2
      }
      cockpitApi.powergenPhotovoltaicPowerUsage(param).then(res => {
        if (res.code == '200') {
          console.log(res.data)
          this.selfUse = Number(res.data.selfUse / 1000000000).toFixed(2)
          this.electricitySale = Number(res.data.electricitySale / 1000000000).toFixed(2)
          this.abandonedLight = Number(res.data.abandonedLight / 1000000000).toFixed(2)
          let total = Number(res.data.selfUse + res.data.electricitySale + res.data.abandonedLight)
          this.totalEnergy = Number(total / 1000000000).toFixed(2)
          let selfUseB = (Number(res.data.selfUse) / total) * 100
          this.selfUseB = selfUseB.toFixed(2)
          let electricitySaleB = (Number(res.data.electricitySale) / total) * 100
          this.electricitySaleB = electricitySaleB.toFixed(2)
          let abandonedLightB = (Number(res.data.abandonedLight) / total) * 100
          this.abandonedLightB = abandonedLightB.toFixed(2)
          this.drawDevice1(res.data.selfUse,res.data.electricitySale,res.data.abandonedLight)
        }
      })
    },
    //查看详细按钮权限
    isStringInArray(str) {
      // let menuList =
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list;
      } else {
        return false;
      }
    },
    currentTime() {
      this.timer = setInterval(() => {
        this.time = moment().format("LTS");
      }, 500);
    },
    init() {
      this.getStationList()
      this.getPowergenComprehensiveData()
      this.getPowergenPowerGenerationStatus()
      this.getPowergenSevenDayPowerGenerationForecast()
      this.getPowergenPhotovoltaicPowerUsage()
      this.queryRealData();
      this.queryTotalPower();
      this.queryAlarmStatistic();
      this.queryWeekElectricity();
      this.queryPowerQuality();
      // this.queryPeriodTime();
      this.queryTaskStatistic();
      this.queryPowerCut();
      this.queryDevice();
    },
    openDialog() {
      this.stationDialog = false;
      this.screenDialog = !this.screenDialog;
    },
    //分中心点击
    branchClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null;
      } else {
        this.branchId = item.id;
      }
      this.getStationThree();
    },
    //产权单位点击
    propertyClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null;
      } else {
        this.propertyId = item.id;
      }
      this.getStationThree();
    },
    //场站选择
    stationClick(item) {
      this.stationId = item.id;
      this.coordinate = [item.longitude, item.latitude];
      this.screenDialog = false;
      this.drawMap("search");
    },
    //场站选择
    dayChange() {
      console.log(this.periodDay)
      this.getPowergenPowerGenerationStatus();
    },
    dayChange2() {
      console.log(this.periodDay2)
      this.getPowergenPhotovoltaicPowerUsage();
    },
    //首页跳转
    jumpHome() {
      this.$router.push("/");
    },
    //退出
    loginOut() {
      // loginApi.exit().then((res) => {
      //   if (res.code === 200) {
      //     this.$message({
      //       message: res.message,
      //       type: "success",
      //       duration: 3000,
      //       customClass: "messageText",
      //     });
      //     sessionStorage.clear();
      //     this.$router.push("/login");
      //   }
      // });
      sessionStorage.clear();
      localStorage.clear();
    },
    //查看详细
    goDetails() {
      sessionStorage.setItem("stationNumber", this.stationInfo.stationNumber);
      sessionStorage.setItem("stationName", this.stationInfo.stationName);
      sessionStorage.setItem("stationId", this.stationId);
      this.$router.push({
        path: "/source",
      });
    },
    // 告警情况统计
    queryAlarmStatistic() {
      cockpitApi.queryAlarmStatistic().then((res) => {
        if (res.code === 200) {
          if (res.data.length !== 0) {
            res.data.forEach((element) => {
              if (element.level_name === "严重告警") {
                this.seriousAlarm = element; //严重
              }
              if (element.level_name === "一般告警") {
                this.averageAlarm = element; //严重
              }
              if (element.level_name === "重大告警") {
                this.majorAlarm = element; //严重
              }
              if (element.level_name === "特大告警") {
                this.specialAlarm = element; //严重
              }
            });
            // this.averageAlarm = res.data[0]; //一般

            // this.majorAlarm = res.data[2]; //重大
            // this.specialAlarm = res.data[3]; //特别重大
          }
        }
      });
    },
    // 24小时实时负载统计
    queryLoadStatistic() {
      cockpitApi.queryLoadStatistic().then((res) => {
        if (res.code === 200) {
          let object = res.data[0];
          let xAxisData = [];
          let seriesData = [];
          for (const key in object) {
            xAxisData.push(key);
            if (object[key]) {
              seriesData.push(object[key]);
            } else {
              seriesData.push(`0`);
            }
          }
          this.drawLoad(xAxisData, seriesData);
        }
      });
    },
    // 计划停电情况统计
    queryPowerCut() {
      cockpitApi.queryPowerCut().then((res) => {
        if (res.code === 200) {
          this.powerCut = res.data;
          // this.drawFailure(res.data);
        }
      });
    },
    // 电能质量情况统计
    queryPowerQuality() {
      cockpitApi.queryPowerQuality().then((res) => {
        if (res.code === 200) {
          let xAxisData = [];
          // 有功功率
          const activePower = res.data.activePower.map((item) => {
            let month = item.nowTime.slice(6, 7) + "月";
            xAxisData.push(month);
            return item.activePower;
          });
          // 视在功率
          const apparentPower = res.data.apparentPower.map((item) => {
            return item.apparentPower;
          });
          // 无功
          const reactivePower = res.data.reactivePower.map((item) => {
            return item.reactivePower;
          });
          this.drawDemand(xAxisData, apparentPower, activePower, reactivePower);
        }
      });
    },
    // 实时数据
    queryRealData() {
      cockpitApi.queryRealData().then((res) => {
        if (res.code === 200) {
          this.realData = res.data;
        }
      });
    },
    // 获取总用电量（GWh）
    queryTotalPower() {
      cockpitApi.queryTotalPower().then((res) => {
        this.totalPower = `${res.toFixed(3)}`;
      });
    },
    // 获取总用电量（GWh）
    queryDevice() {
      cockpitApi.queryDevice().then((res) => {
        if (res.code === 200) {
          const companyData = res.data.map((item) => {
            return item.companyName;
          });
          const before = res.data.map((item) => {
            return Number(item.before2012);
          });
          const between = res.data.map((item) => {
            return item.between2012And2019;
          });
          const after = res.data.map((item) => {
            return item.after2019;
          });
          this.drawDevice(companyData, before, between, after);
        }
      });
    },
    // 抢修/维修情况统计
    queryTaskStatistic() {
      cockpitApi.queryTaskStatistic().then((res) => {
        if (res.code === 200) {
          this.rushRepair = res.data[0];
          this.drawRushRepair(this.rushRepair);
          this.keepRepair = res.data[1];
          this.drawKeepRepair(this.keepRepair);
        }
      });
    },
    // 总电量峰谷平
    queryPeriodTime() {
      this.drawConsumption();
      // cockpitApi.queryPeriodTime({ month: this.periodMonth }).then((res) => {
      //   if (res.code === 200) {
      //     let xAxisData = res.data.peakPower.map((item) => {
      //       let day = item.nowTime.slice(8, 10) + "日";
      //       return day;
      //     });
      //     // 尖
      //     let sharpSeries;
      //     if( res.data.tipPower){
      //     sharpSeries = res.data.tipPower.map((item) => {
      //         return item.onceEpaddIns;
      //       });
      //     }
      //     // 峰
      //     let peakSeries = res.data.peakPower.map((item) => {
      //       return item.onceEpaddIns;
      //     });
      //     // 平
      //     let averageSeries = res.data.flatPower.map((item) => {
      //       return item.onceEpaddIns;
      //     });
      //     // 谷
      //     let valleySeries = res.data.valleyPower.map((item) => {
      //       return item.onceEpaddIns;
      //     });
      //     this.drawConsumption(
      //       xAxisData,
      //       sharpSeries,
      //       peakSeries,
      //       averageSeries,
      //       valleySeries
      //     );
      //   }
      // });
    },
    nextWeek() {
      let array = [];
      var today = new Date();
      for (var i = 1; i <= 7; i++) {
        var pastDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000); // 计算 i 天前的日期
        var month = pastDate.getMonth() + 1; // 获取月份（月份从0开始，所以要加1）
        var day = pastDate.getDate(); // 获取日期
        // 格式化月份和日期，确保是两位数
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        array.push(month + '-' + day);
      }
      array.sort();
      return array
    },
    // 近七日用电趋势
    queryWeekElectricity() {
      cockpitApi.queryWeekElectricity().then((res) => {
        if (res.code === 200) {
          let xAxisData = this.nextWeek();
          let seriesData = res.data.map((item) => {
            return item.onceEpaddIns;
          });
          this.drawTrend(xAxisData, seriesData);
        }
      });
    },
    // 抢修情况统计
    drawRushRepair(rushRepair) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#40ECC2", "#F6CD29"],

        series: [
          {
            name: "抢修情况统计",
            type: "pie",
            width: "64%",
            height: "64%",
            radius: ["55%", "70%"],
            center: ["45%", "60%"], // 调整饼图位置
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {value: rushRepair.taskCompleted, name: "已完成"},
              {value: rushRepair.taskUnfinished, name: "未完成"},
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("rushRepairChart"))
          .setOption(option);
    },
    // 维修情况统计
    drawKeepRepair(keepRepair) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#40ECC2", "#F6CD29"],
        series: [
          {
            name: "维修情况统计",
            type: "pie",
            width: "64%",
            height: "64%",
            radius: ["55%", "70%"],
            center: ["34%", "60%"], // 调整饼图位置
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {value: keepRepair.taskCompleted, name: "已完成"},
              {value: keepRepair.taskUnfinished, name: "未完成"},
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("keepRepairChart"))
          .setOption(option);
    },
    // 近七日用电趋势
    drawTrend(xAxisData, seriesData) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          data: ["总用电量"],
          right: "30px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
            fontSize: 12,
          },
        },
        grid: {
          bottom: 66,
          left: 70,
        },
        xAxis: {
          data: xAxisData,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "用电量:MWh",
          nameTextStyle: {
            color: "#C5D8FF",
            fontSize: 10,
          },
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "总用电量",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#40ECC2"},
                {offset: 1, color: "#6DFAD8"},
              ]),
            },
            // label: {
            //   show: true,
            //   position: "top",
            //   textStyle: {
            //     //数值样式
            //     fontSize: "12px",
            //     color: "#C5D8FF",
            //   },
            // },
            data: seriesData,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("trendChart")).setOption(option);
    },
    // 电能质量情况统计
    drawDemand(xAxisData, apparentPower, activePower, reactivePower) {
      const demandChart = echarts.init(document.getElementById("demandChart"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "健康指数",
            type: "pie",
            width: "90%",
            height: "90%",
            radius: ["65%", "80%"],
            center: ["55%", "55%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                value: 21,
                name: "缺陷数",
                itemStyle: {color: "#40ECC2"},
              },
              {
                value: 85,
                name: "消缺数",
                itemStyle: {color: "#51a1ff"},
              },
            ],
          },
        ],
      };
      // demandChart.setOption(option);
      // 使用刚指定的配置项和数据显示图表。
      demandChart.setOption(option);
    },
    // 发电情况echarts
    drawConsumption(data) {
      let arrX = []
      let arrZ = []
      let arrW = []
      data.externalSale.forEach(w => {
        arrX.push(w.hour)
        arrW.push(w.gridEnergy)
      })
      data.selfConsumption.forEach(z => {
        arrZ.push(z.energy)
      })
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          right: "20%",
          top: "2px",
          textStyle: {
            color: "#C5D8FF",
          },
        },
        xAxis: {
          name: '',
          nameLocation: 'end',
          type: 'category',
          data: arrX,
          nameTextStyle: {
            color: "#C5D8FF",
          },
          axisLabel: {
            interval: 0,
            formatter: function (params) {
              var str = ""
              var paramsLen = params.length
              var len = 2
              var rowNumber = Math.ceil(paramsLen / len);
              if (paramsLen > len) {        //大于设定的len就换行，不大于就不变化
                for (var i = 0; i < rowNumber; i++) {
                  var temp = "";            // 表示每一次截取的字符串
                  var start = i * len;      // 开始截取的位置
                  var end = start + len;    // 结束截取的位置
                  if (i == rowNumber - 1) { // 最后一次不换行
                    temp = params.substring(start, paramsLen - 1);
                  } else {                  // 每一次拼接字符串并换行
                    temp = params.substring(start, end) + "\n";
                  }
                  str += temp;              // 最终拼成的字符串
                }
              } else {                      // 给新的字符串赋值
                str = params;
              }
              return str;                   //返回字符串
            },
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "电量:MWh",
          nameTextStyle: {
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "自用",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#176ad2'
            },
            data: arrZ,
          },
          {
            name: "外售",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 15,
            itemStyle: {
              color: '#3de2bc'
            },
            data: arrW,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts
          .init(document.getElementById("consumptionChart"))
          .setOption(option);
    },
    //各中心新旧设备占比
    drawDevice(companyData, before, between, after) {
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "健康指数",
            type: "pie",
            width: "90%",
            height: "90%",
            radius: ["65%", "80%"],
            center: ["55%", "55%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                value: 21,
                name: "缺陷数",
                itemStyle: {color: "#40ECC2"},
              },
              {
                value: 85,
                name: "消缺数",
                itemStyle: {color: "#51a1ff"},
              },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("deviceChart")).setOption(option);
    },
    //7日发电与测量
    drawLoad(data) {
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.day)
        arrY.push(item.energy)
      })

      var option = {
        tooltip: {
          trigger: "axis",
        },
        color: ["#35f3cc"],
        legend: {
          icon: "square",
          itemWidth: 6,
          itemHeight: 6,
          right: "5%",
          top: '30',
          textStyle: {
            color: "#C5D8FF",
          },
        },
        grid: {
          bottom: 66,
          left: 80
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "发电量:MWh",
          nameTextStyle: {
            fontSize: 10,
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "发电量",
            type: "line",
            stack: "Total",
            // showSymbol: false,
            lineStyle: {
              color: "#35f3cc", // 可选，设置线条颜色
              width: 2,
            },
            symbol: "emptyCircle", // 设置为圆形空心
            itemStyle: {
              normal: {
                borderColor: "#23f3cc", // 设置空心圆边框颜色},
                borderWidth: 2, // 边框宽度
                // color: "#01071a", // 可选，设置空心圆的填充颜色
              },
            },
            symbolSize: 8, // 设置空心圆的大小
            areaStyle: {
              // opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(35,243,204,0.39)",
                },
                {
                  offset: 1,
                  color: "rgba(35, 243, 204,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: arrY,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("loadChart")).setOption(option);
    },
    //光伏发电使用情况echarts
    drawDevice1(selfUse,electricitySale,abandonedLight) {
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "发电量",
            type: "pie",
            width: "90%",
            height: "90%",
            radius: ["65%", "80%"],
            center: ["55%", "55%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                value: selfUse,
                name: "自用",
                itemStyle: {color: "#3666c6"},
              },
              {
                value: electricitySale,
                name: "售电",
                itemStyle: {color: "#40ecc2"},
              },
              {
                value: abandonedLight,
                name: "弃光",
                itemStyle: {color: "#f6cd29"},
              },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("deviceChart1")).setOption(option);
    },
    // 计划外停电情况统计
    drawFailure(operatedCapacity, plannedCapacity, underConstructionCapacity) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "已投运",
            type: "pie",
            width: "90%",
            height: "90%",
            radius: ["65%", "80%"],
            center: ["55%", "55%"], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: [
              {
                value: operatedCapacity,
                name: "已投运",
                itemStyle: {color: "#40ECC2"},
              },
              {
                value: plannedCapacity,
                name: "计划",
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#4683FF",
                    },
                    {
                      offset: 1,
                      color: "#2D54A4",
                    },
                  ]),
                },
              },
              {
                value: underConstructionCapacity,
                name: "在建",
                itemStyle: {color: "#F6CD29"},
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("failureChart")).setOption(option);
    },
    getCenter(center) {
      let stationData = this.stationData.map((item) => {
        let mark = {};
        mark.id = item.id;
        mark.name = item.stationName;
        mark.type = "station";
        mark.value = [item.longitude, item.latitude];
        return mark;
      });
      this.mapChart = echarts.init(document.getElementById("mapChart"));
      var option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "jingjinji",
          roam: true,
          zoom: 1.04,
          scaleLimit: {
            min: 1.04,
          },
          animationDurationUpdate: 0,
          top: 20,
          bottom: 20,
          tooltip: {
            show: false,
          },
          label: {
            show: false,
          },
          itemStyle: {
            normal: {
              borderColor: "#3095ff",
              areaColor: "040a36",
              borderWidth: 2, // 设置边缘线宽度为1
            },
          },
        },
        series: [
          {
            name: "station",
            type: "scatter",
            coordinateSystem: "geo",
            symbol:
                "image://" +
                require("@/assets/images/electricity/coordinate.png"),
            symbolSize: 32,
            data: stationData,
          },
        ],
      };
      option.geo.center = center;
      option.geo.zoom = 3;
      mapChart.setOption(option);
    },
    // 地图
    drawMap(type) {
      let stationData = this.stationData.map((item) => {
        let mark = {};
        mark.id = item.id;
        mark.name = item.stationName;
        mark.type = "station";
        mark.value = [item.longitude, item.latitude];
        return mark;
      });
      console.log("场站数据", stationData);
      const _this = this;

      // 销毁实例的写法
      if (this.mapChart) {
        this.mapChart.dispose();
      }
      this.mapChart = echarts.init(document.getElementById("mapChart"));

      // let mapChart = echarts.init(document.getElementById("mapChart"));
      echarts.registerMap("jingjinji", jingjinji);
      var option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "jingjinji",
          roam: true,
          zoom: 1.04,
          scaleLimit: {
            min: 1.04,
          },
          animationDurationUpdate: 0,
          top: 20,
          bottom: 20,
          tooltip: {
            show: false,
          },
          label: {
            show: false,
          },
          itemStyle: {
            normal: {
              borderColor: "#3095ff",
              areaColor: "040a36",
              borderWidth: 2, // 设置边缘线宽度为1
            },
          },
        },
        series: [
          {
            name: "station",
            type: "scatter",
            coordinateSystem: "geo",
            symbol:
                "image://" +
                require("@/assets/images/electricity/coordinate.png"),
            symbolSize: 32,
            data: stationData,
          },
        ],
      };
      if (this.coordinate.length !== 0) {
        console.log("中心点", this.coordinate);
        option.geo.center = this.coordinate;
        option.geo.zoom = 3;
        if (type && type === "search") {
          cockpitApi.powergenSelectInfoById({id: this.stationId})
              .then((res) => {
                if (res.code === 200) {
                  this.stationDialog = true;
                  this.stationInfo = res.data;
                }
              });
        }
      }

      this.mapChart.setOption(option);
      console.log("配置项", option);
      //点击事件
      this.mapChart.on("click", function (params) {
        _this.stationDialog = false;
        if (params.data) {
          _this.stationId = params.data.id;
          cockpitApi
              .powergenSelectInfoById({id: params.data.id})
              .then((res) => {
                if (res.code === 200) {
                  _this.coordinate = params.data.value;
                  _this.drawMap();
                  _this.stationDialog = true;
                  _this.stationInfo = res.data;
                }
              });
        }
      });
      this.mapChart.on("geoRoam", function () {
        _this.stationDialog = false;
      });
    },
    // 跳转报警列表
    goAlarmList(alarmId) {
      this.$router.push({
        name: 'AllAlarm',
        query: {
          alarmId
        }
      })
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.flex-horizontal-between.link li {
  cursor: pointer;
}

.el-date-editor {
  width: 106px;
  border: 1px solid #0e447e;
}

.el-date-editor :deep() .el-icon-date {
  color: #aed6ff;
}

.el-date-editor :deep() .el-input__inner {
  width: 100%;
}

.el-date-editor :deep() .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  width: 0.8rem;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #d9e3ff;
}

.el-date-editor :deep() .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}

#electricity {
  width: 1920px;
  height: 1080px;
  background: url("../../assets/images/electricity/background.jpg") no-repeat center;
  background-size: cover;
  font-size: 16px;
  box-shadow: 0px 1px 1px 0px rgba(12, 24, 58, 0.69);
  padding-bottom: 24px;
  box-sizing: border-box;
  position: relative;
}

.header {
  width: 1920px;
  height: 110px;
  background: url("../../assets/images/electricity/header-background.png") no-repeat center;
  background-size: cover;
  padding: 15px 21px 0 29px;
  box-sizing: border-box;
  position: relative;
}

.nav {
  display: flex;
}

.nav li {
  height: 20px;
  display: flex;
  align-items: center;
}

.home-page img {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.home-page,
.exit {
  cursor: pointer;
}

.date img {
  margin: 0 10px 0 19px;
}

.nav span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

/* 标题 */
.title {
  height: 42px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 22px;
  left: 691px;
}

.title span {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-left: 12px;
}

/* 操作 */
.operate {
  display: flex;
}

.operate li {
  height: 28px;
  display: flex;
  align-items: center;
}

.address span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8d9ac7;
  margin: 0 24px 0 11px;
}

.user span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e0e8ff;
  margin: 0 10px 0 7px;
}

.line {
  width: 1px;
  height: 21px;
  background-color: rgb(71, 50, 95);
  margin-right: 12px;
}

.exit span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  margin-left: 8px;
}

/* main */
.main {
  display: flex;
}

/* 左边 */
.content-left {
  width: 514px;
  padding-left: 30px;
  box-sizing: border-box;
  margin-top: -18px;
}

.subtitle {
  height: 24px;
  display: flex;
  /* align-items: center; */
}

.subtitle img {
  width: 32px;
  height: 15px;
}

.subtitle p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c5d8ff;
}

.subtitle span {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #c5d8ff;
}

/* 实时数据 */
.now-data {
  margin-top: 16px;
}

.now-data li {
  position: relative;
}

.station span,
.transformer span {
  font-size: 32px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f6cd29;
}

.transformer div,
.station div {
  position: absolute;
  top: 15px;
  left: 95px;
}

.station p,
.transformer p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.task {
  position: relative;
}

.task span {
  font-size: 28px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #40ecc2;
}

.task p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.daily-tasks {
  position: absolute;
  top: 24px;
  left: 95px;
}

.current-plan {
  position: absolute;
  top: 24px;
  left: 237px;
}

.completion-rate {
  position: absolute;
  top: 24px;
  left: 381px;
}

/* 综合数据 */
.fault {
  width: 484px;
  height: 270px;
  background: url("../../assets/images/electricity/fault-background.png") no-repeat center;
  background-size: cover;
  margin-top: 15px;
  position: relative;
}

#keepRepairChart,
#rushRepairChart {
  width: 484px;
  height: 218px;
}

.fault-legend {
  position: absolute;
  top: 69px;
}

.rush-repair {
  left: 125px;
}

.keep-repair {
  right: 22px;
}

.rush-total {
  left: 38px;
}

.repair-total {
  right: 159px;
}

.task-name {
  font-size: 16px;
  font-weight: 400;
  color: #c5d8ff;
  position: absolute;
  bottom: 24px;
}

.task-total {
  width: 64px;
  text-align: center;
  position: absolute;
  top: 78px;
}

.task-total div {
  font-size: 14px;
  font-weight: 400;
  color: #c5d8ff;
}

.task-total .num {
  font-size: 24px;
  font-family: DIN;
  font-weight: 400;
  color: #f6cd29;
}

.fault-legend li {
  width: 105px;
  height: 35px;
  background: url("../../assets/images/electricity/fault-legend.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.fault-legend li:first-child {
  margin-bottom: 7px;
}

.fault-legend li div {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin: 0 8px;
}

.fault-legend li p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
}

.trend {
  width: 484px;
  height: 310px;
  background: url("../../assets/images/electricity/trend-background.png") no-repeat center;
  background-size: cover;
  margin-top: 12px;
}

#shgx {
  width: 421px;
  height: 243px;
  background: url("../../assets/images/electricity/shgx2.png") no-repeat center;
  background-size: cover;
  margin-top: 16px;
  position: relative;
}

#shgx p {
  position: absolute;
}

#trendChart {
  width: 484px;
  height: 218px;
}

.demand {
  width: 484px;
  height: 353px;
  background: url("../../assets/images/electricity/demand-background.png") no-repeat center;
  background-size: cover;
  margin-top: 14px;
  position: relative;
}

#demandChart, #deviceChart {
  width: 213px;
  height: 191px;
  background: url("../../assets/images/electricity/jkxql.png") no-repeat center;
  position: absolute;
  top: 54px;
}
#demandChart, #deviceChart1 {
  width: 213px;
  height: 191px;
  background: url("../../assets/images/electricity/jkxql.png") no-repeat center;
  position: absolute;
  top: 54px;
}

.demand .titles, .device .titles {
  position: absolute;
  top: 124px;
  left: 68px;
}

.demand .titles p, .device .titles p {
  font-size: 26px;
  color: #51a1ff;
}

.demand .titles span, .device .titles span {
  font-size: 14px;
  color: #bccaf3;
  margin-left: 15px;
}

.device .titles p {
  font-size: 24px;
}

.device .titles span {
  font-size: 12px;
  color: #bccaf3;
  margin-left: 0 !important;
  /* margin-left: 10px; */
}

.demand .failure-legend {
  top: 100px;
}

.demand .failure-legend li {
  width: 174px;
  height: 35px;
  background-size: cover;
}

.demand .failure-legend .li1 {
  background: url("../../assets/images/electricity/qxs.png") no-repeat center;
}

.demand .failure-legend .li2 {
  background: url("../../assets/images/electricity/xqs.png") no-repeat center;
}

.demand .bottom, .device .bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
}

.demand .bottom .list_one {
  width: 184px;
  height: 84px;
  background-size: cover;
}

.device .bottom .list_one {
  width: 139px;
  height: 78px;
  background-size: cover;
  background: url("../../assets/images/electricity/gffdsyqk2.png") no-repeat center;
  margin-right: 15px;
}

.demand .bottom .list_two {
  background: url("../../assets/images/electricity/sbjkzt.png") no-repeat center;
  margin-right: 20px;
}

.demand .bottom .list_three {
  background: url("../../assets/images/electricity/sbjkl.png") no-repeat center;
}

.demand .bottom .list_one p {
  position: absolute;
  font-size: 20px;
}

.demand .bottom .list_one span {
  position: absolute;
  font-size: 14px;
  color: #bccaf3;
}

.demand .bottom .list_two p {
  position: absolute;
  left: 90px;
  top: 21px;
  font-size: 20px;
  color: #40ecc2;
}

.demand .bottom .list_three p {
  position: absolute;
  left: 295px;
  top: 21px;
  font-size: 20px;
  color: #51a1ff;
}

.demand .bottom .list_two span {
  position: absolute;
  left: 90px;
  bottom: 17px;
  font-size: 14px;
  color: #bccaf3;
}

.demand .bottom .list_three span {
  position: absolute;
  left: 295px;
  bottom: 17px;
  font-size: 14px;
  color: #bccaf3;
}

.device .fault-legend li {
  width: 174px;
  height: 35px;
  margin-left: 118px;
  margin-bottom: 15px;
  background: url("../../assets/images/electricity/gffdsyqk1.png") no-repeat center;
}

.device .bottom .list_one p {
  margin: 15px 0 0 34px;
  font-size: 23px;
}

.device .bottom .list_one span {
  margin-left: 34px;
  font-size: 14px;
  color: #bccaf3;
}

.device .bottom .list_two p {
  color: #3666c6;
  font-size: 23px;
}

.device .bottom .list_three p {
  color: #40ecc2;
  font-size: 23px;
}

.device .bottom .list_four p {
  color: #f6cd29;
  font-size: 23px;
}

/* 中 */
.middle {
  width: 891px;
  box-sizing: border-box;
  position: relative;
}

.map {
  width: 800px;
  height: 700px;
}

#mapChart {
  width: 900px;
  height: 700px;
}

.total-electricity {
  position: absolute;
  top: 37px;
  left: 247px;
  z-index: 3;
}

.total-electricity .text {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 11px;
}

.total {
  display: flex;
  margin-top: 16px;
}

.total li {
  width: 41px;
  height: 72px;
  background: url("../../assets/images/electricity/number-background.png") no-repeat center;
  background-size: cover;
  margin-right: 13px;
  font-size: 50px;
  font-family: DIN;
  font-weight: 400;
  color: #e9f1fa;
  text-align: center;
  line-height: 72px;
}

.total li:last-child {
  margin-right: 0;
}

.pop-ups {
  width: 265px;
  height: 369px;
  background: url("../../assets/images/electricity/eject-background.png") no-repeat center;
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  position: fixed;
  top: 417px;
  right: 677px;
}

.dialog-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin: 0 0 17px 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-form {
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 9px;
  box-sizing: border-box;
  padding-left: 24px;
}

.dialog-form div::after {
  content: ":";
  margin: 0 3px;
}

.year-electricity {
  width: 212px;
  height: 70px;
  background: url("../../assets/images/electricity/year-background.png") no-repeat center;
  background-size: cover;
  margin: 0 29px 13px 20px;
  position: relative;
}

.year-electricity div {
  position: absolute;
  top: 12px;
  left: 69px;
}

.year-electricity span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #40ecc2;
}

.year-electricity p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
}

.dialog-content button {
  width: 206px;
  height: 38px;
  background: #1c479e;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  text-align: center;
  line-height: 38px;
  margin-left: 16px;
  border: 0;
  cursor: pointer;
}

.coordinate {
  position: absolute;
  top: 312px;
  left: 340px;
}

.arrow {
  width: 228px;
  height: 84px;
  background: url("../../assets/images/electricity/arrow.png") no-repeat center;
  background-size: cover;
  margin-right: 4px;
  position: fixed;
  top: 379px;
  right: 792px;
  pointer-events: none;
}

.station-info {
}

.consumption {
  position: absolute;
  bottom: 17px;
  left: 535px;
  overflow: hidden;
  z-index: 10;
  width: 850px;
  height: 255px;
  background: url("../../assets/images/electricity/fdqk.png") no-repeat center;
  background-size: cover;
  margin-top: 20px;
}

#consumptionChart {
  width: 850px;
  height: 250px;
}

.content-right {
  width: 484px;
  padding-right: 31px;
  box-sizing: border-box;
  margin-top: -18px;
}

.accident {
  margin-top: 16px;
}

.accident li {
  position: relative;
  margin: 0 20px 4px 0;
}

.accident li:nth-child(even) {
  margin-right: 0;
}

.accident li div {
  position: absolute;
  top: 12px;
  left: 92px;
}

.accident span {
  font-size: 32px;
  font-family: Bahnschrift;
  font-weight: 400;
}

.accident p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c5d8ff;
  margin-top: -4px;
}

.failure {
  width: 484px;
  height: 218px;
  background: url("../../assets/images/electricity/failure-background.png") no-repeat center;
  background-size: cover;
  margin-top: 8px;
  position: relative;
}

#failureChart {
  width: 250px;
  height: 201px;
  background: url("../../assets/images/electricity/zhsj1.png") no-repeat center;
  /* background-size: cover; */
  z-index: 999;
  margin-top: 20px;
}

.failure-legend {
  position: absolute;
  top: 32px;
  left: 252px;
}

.failure-legend li {
  width: 204px;
  height: 35px;
  background: url("../../assets/images/electricity/fault-legend.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  position: relative;
}

.failure-legend .failure-square {
  width: 8px;
  height: 8px;
  background: linear-gradient(0deg, #4683ff, #2d54a4);
  border-radius: 2px;
  margin: 0 10px 0 19px;
}

.failure-legend li:last-child {
  margin-bottom: 0;
}

.failure-legend li:last-child div {
  background: linear-gradient(0deg, #ff6a24, #ff8c56);
}

.failure-legend p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
}

/* 计划停电 */
.failure-total {
  display: flex;
  align-items: center;
}

.failure-total img {
  width: 8px;
  height: 8px;
  margin: 0 3px 0 6px;
}

.failure-total span {
  font-size: 20px;
  font-family: DIN;
  font-weight: 400;
  color: #f6cd29;
}

.failure-total p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a7cdec;
}

.load {
  width: 484px;
  height: 358px;
  background: url("../../assets/images/electricity/load-background.png") no-repeat center;
  background-size: cover;
  margin: 6px 0 16px;
}

#loadChart {
  width: 484px;
  height: 350px;
}

.device {
  width: 484px;
  height: 358px;
  background: url("../../assets/images/electricity/device-background.png") no-repeat center;
  background-size: cover;
  margin-top: 9px;
  position: relative;
}

/*
#deviceChart {
  width: 484px;
  height: 250px;
} */

/* 下拉菜单 */
.select {
  width: 60px;
  height: 23px;
  background: rgba(41, 88, 164, 0.34);
  border-radius: 2px;
  display: flex;
  cursor: pointer;
}

.select span {
  width: 37px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5d8ff;
  border-right: 1px solid #040941;
  line-height: 23px;
  text-align: center;
}

.select p {
  width: 22px;
  text-align: center;
}

.menu-content {
  width: 60px;
  height: 100px;
  background-color: #10225a;
  margin-top: 2px;
  padding: 4px 0;
  box-sizing: border-box;
  overflow-y: scroll;
  display: none;
}

.dropdown-menu:hover .menu-content {
  display: block;
}

.menu-content li {
  font-size: 12px;
  font-family: Microsoft YaHei;
  color: #c5d8ff;
  text-align: center;
}

::v-deep .menu-content::-webkit-scrollbar {
  width: 4px;
  /* 设置滚动条高度 */
  background-color: #091856;
  /* 设置滚动条背景色 */
}

::v-deep .menu-content::-webkit-scrollbar-thumb {
  background-color: #193f92;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.dialog-title {
  display: flex;
  margin: 0 26px 21px 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin: 4px 8px 0 0;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-content {
  height: 220px;
  margin: 21px 0 0 15px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.dialog-content li {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 12px;
}

.dialog-content div::after {
  content: ":";
  margin: 0 3px;
}

.dialog-content .year-GWh::after {
  content: "";
}

.weather {
  position: absolute;
  right: 31px;
  top: 51px;
}

.weather li {
  width: 51px;
  height: 67px;
  margin-bottom: 16px;
}

.bus-stop {
  width: 52px;
  height: 67px;
  background: url("../../assets/images/cockpit/bus-stop.png") no-repeat center;
  background-size: cover;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 41px;
  position: absolute;
  top: 25px;
  left: 20px;
  z-index: 4;
}

#chart {
  width: 423px;
  height: 263px;
  margin-top: -20px;
}

.dialog {
  width: 690px;
  height: 714px;
  background: url("../../assets/images/dialog/background.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 32%;
  top: 12.5%;
  padding: 19px 19px 0 25px;
  box-sizing: border-box;
  z-index: 100;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.close img {
  width: 11px;
  height: 11px;
}

.screen-title {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
  text-align: center;
}

.screen-subtitle {
  display: flex;
  align-items: center;
  margin: 12px 0 13px 0;
}

.screen-subtitle img {
  width: 20px;
  height: 20px;
}

.screen-subtitle span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 16px;
}

.screen-content li {
  width: 150px;
  height: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  background-color: rgba(14, 100, 217, 0.11);
  margin: 0 7px 8px 0;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  /* 确保文本不会换行 */
  overflow: hidden;
  /* 隐藏超出容器的文本 */
  text-overflow: ellipsis;
  /* 当文本超出容器时显示省略号 */
}

.screen-content {
  height: 1.5rem;
  overflow: auto;
  width: 6.5rem;
  /* display: flex;
  flex-wrap: wrap; */
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.screen-content::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.station-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 13px 0;
}

.station-title .screen-subtitle {
  margin: 0;
}

.search {
  display: flex;
}

.search :deep() input {
  width: 480px;
  height: 30px;
  background-color: rgba(14, 100, 217, 0.11);
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: 0;
  box-sizing: border-box;
}

.search :deep() button {
  width: 75px;
  height: 30px;
  background-color: #176ad2;
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin-left: -10px;
}

.screen-content .all-station {
  width: 205px;
}

.dialog-footer {
  margin: 7px 0 17px 0;
  display: flex;
  justify-content: center;
}

.dialog-footer button {
  width: 76px;
  height: 32px;
  background-color: #176ad2;
  color: #ffffff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: none;
}

.dialog-footer button:first-child {
  margin-right: 15px;
  background-color: #092259;
  color: #aed6ff;
}

.el-date-editor :deep() .el-input__inner {
  width: 100% !important;
}

.el-date-editor {
  width: 105px;
  position: absolute;
  right: 14px;
  top: 22px;
  z-index: 10;
  border: 1px solid #0e447e;
}

/* 修改日期选择器样式 */
::v-deep .el-icon-date {
  color: #aed6ff;
}

:deep() .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  width: 0.8rem;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #d9e3ff;
  padding-left: 27px !important;
  padding-right: 0px !important;
}

:deep() .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}
</style>
<style lang="css">
/*  */
.echarts-picker {
  background: #041c4c;
  color: #aed6ff;
  border-color: #041c4c;
}

.echarts-picker .el-picker-panel__body .el-date-picker__header-label {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__icon-btn {
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-year-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .cell {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker .el-picker-panel__body .el-picker-panel__content .el-month-table .today .cell {
  background: #041c4c;
  color: #409eff;
}

.echarts-picker .el-picker-panel__body .el-date-table .disabled div {
  background: #041c4c;
  color: #aed6ff;
}

.echarts-picker[x-placement^="bottom"] .popper__arrow::after {
  top: 0 !important;
  border-bottom-color: #041c4c !important;
}

.echarts-picker[x-placement^="top"] .popper__arrow::after {
  bottom: 0 !important;
  border-top-color: #041c4c !important;
}

.consumption-chart-icon {
  position: absolute;
  left: 190px;
}

.consumption-chart-icon li {
  display: inline-block;
  margin-left: 10px;
  color: #c5d8ff;
  font-size: 14px;
}

.consumption-chart-icon li:before {
  content: "■";
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2px;
  margin-right: 6px;
}

.consumption-chart-icon li.power-top:before {
  color: rgba(255, 66, 66, 0.3);
}

.consumption-chart-icon li.power-peak:before {
  color: rgba(255, 199, 75, 0.3);
}

.consumption-chart-icon li.power-flat:before {
  color: rgba(61, 226, 188, 0.3);
}

.consumption-chart-icon li.power-valley:before {
  color: rgba(58, 109, 212, 0.3);
}
</style>
